@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200&family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vollkorn:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=BIZ+UDMincho&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Volkhov&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Vollkorn:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400&display=swap');


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: none;
  background-color: blue;
  color: white;
  border-radius: 10px;
  font-size: 18px;
  box-shadow: 2px 2px 4px 3px rgba(0, 0, 0, 0.7);
}

/*********************** Error msg section *************************/

.error {
  font-size: 60px;
  text-align: center;
  letter-spacing: 1px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.d-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: burlywood;
}

/*********************** Navbar section *************************/

/* .home-btn {
  position: fixed;
  top: 2%;
  right: 2%;
  z-index: 2;
  background-color: hsl(180, 91%, 37%);
  font-size: 23px;
  text-decoration: none;
  width: 70px;
  height: 40px;
  border-radius: 10px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: hsl(241, 73%, 13%);
  padding-top: 2px;
  text-align: center;
  box-shadow: 1px 1px 1px 1px black;
  cursor: pointer;
  transition: background-color 0.2s linear;
}

.home-btn-dishlist {
  bottom: 2%;
  left: 2%;
  top: unset;
}

.home-btn:hover {
  background-color: hsl(180, 87%, 48%);
} */

/*********************** Home section *************************/

.my-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 3px solid black;
  background-repeat: no-repeat;
  margin: auto 0;

}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #575656;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* ::-webkit-scrollbar { */
  /* display: none; */
/* } */

/* * { */
  /* scrollbar-width: none;  Firefox */
/* } */


.about {
  display: flex;
  flex-direction: column;
  margin-top: 120px;
}

.about-me, .about-project-header {
  font-size: 2.1rem;
  line-height: 1.2;
  color: white;
  font-family: 'Barlow Condensed', sans-serif;
  letter-spacing: 2px;
}

.about-header {
  color: #C28CD0;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 1.2rem;
}

.about > h4 {
  padding-bottom: 10px;
  color: white;
  font-weight: normal;
  font-family: 'Poiret One';
  text-align: left;
}

.skills {
  display: flex;
  justify-content: space-evenly;
  flex-grow: 1;
  margin-bottom: 50px;
}

.contact {
  width: 150px;
  font-size: 1.5rem;
  border: none;
  font-weight: 500;
  letter-spacing: 1px;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.contact-icons {
  color: white;
  transform: scale(0.85);
  margin-top: 2px;
  transition: transform 0.5s;
}

.contact-icons:hover {
  transform: scale(1.005);
}

.about-project-header {
  align-self: center;
  margin-top: 7rem;
}

.arrow {
  color: white;
  font-weight: bold;
  padding: 0;
  position: relative;
  margin: 0;
  animation: bounce 1.2s infinite;
  align-self: flex-end;
  transform: translateY(-1rem);
}

@keyframes bounce {
  /* 0% {
    transform: translateY(0);
  } */

  30% {
    transform: translateY(0);
  }

  /* 80% {
    transform: translateY(-2px);
  }
   */
  100% {
    transform: translateY(-1rem);
  }
}

@media screen and (min-width: 486px) {

  .about-me, .about-project-header {
    font-size: 2.4rem;
  }

  .about-header {
    font-size: 1.5rem;
  }

  .about > h4 {
    font-size: 0.4rem;
  }
}

@media screen and (min-width: 638px) {
  .about-me, .about-project-header {
    font-size: 4.4rem;
  }

  .about-header {
    font-size: 2rem;
  }

  .about > h4 {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 798px) {
  .about-me, .about-project-header {
    font-size: 5.4rem;
  }

  .about-header {
    font-size: 2rem;
  }

  .about > h4 {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 950px) {
  .about-me, .about-project-header {
    font-size: 6.3rem;
  }
}


/*********************************** Projects section *************************************/

.projects {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-y: hidden;
}

.project {
  display: flex;
  font-family: 'Sarabun', sans-serif;
  flex-direction: column;
  letter-spacing: 1px;
  padding: 100px 0px;
  --bottom-to-top-delay: 0.3s;
  opacity: 1;
  align-items: center;
  text-align: center;
}

.project-img {
  max-width: 200px;
  max-height: 200px;
  margin: 30px 0;
}

.project-info {
  display: flex;
  flex-direction: column;
}

#proj2, #proj4 {
  justify-content: flex-end;
}


@keyframes left-to-right {
  100% {
    opacity: 1;
  }
}

@keyframes right-to-left {
  0% {
    transform: translateY(50px);
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.project-info > h3 {
  padding-top: 5px;
  font-size: 2rem;
  color: white;
  letter-spacing: 2px;
  font-family: 'Titan One', cursive;
}

.project-info > h4 {
  color: black;
  padding: 35px 0 35px 0;
  font-size: 0.8rem;
  white-space: pre-line;
}

.pbtn {
  /* border: none;
  background-color: hsl(14, 49%, 23%);
  border-radius: 20px;
  height: 25px;
  width: 80px;
  color: rgb(240, 240, 240);
  text-align: center;
  padding-top: 3px;
  font-family: 'Vollkorn', serif;
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.7); */
  border: none;
  color: black;
  background-color: white;
  width: 200px;
  height: 35px;
  align-self: center;
  text-align: center;
  padding: 5px;
  text-decoration: none;
  font-family: 'Bree Serif', serif;
  transform: scale(0.9);
  transition: all 0.4s linear;
}

.pbtn:hover {
  transform: scale(1);
}

@media screen and (min-width: 480px) {

   .project {
    opacity: 0;
   }

   #proj1.active, #proj3.active {
    align-items: center;
    animation: right-to-left 0.8s ease-in-out forwards;
   }

   #proj2.active, #proj4.active {
    animation: right-to-left 0.8s ease-in-out forwards;
   }
}

@media screen and (min-width: 650px) {
  .project-img {
    max-width: 250px;
    max-height: 250px;
  }

  .project-info > h3 {
    font-size: 2.5rem;
  }
  
  .project-info > h4 {
    font-size: 1.2rem;
    white-space: pre-line;
  }
  
  .pbtn {
    font-size: 1rem;
  }
}

@media screen and (min-width: 812px) {
  .project-img {
    max-width: 400px;
    max-height: 400px;
  }

  .project-info > h3 {
    font-size: 3.5rem;
  }
  
  .project-info > h4 {
    font-size: 1.5rem;
    white-space: pre-line;
  }
  
  .pbtn {
    font-size: 2rem;
    width: auto;
    height: auto;
  }
}

@media screen and (min-width: 1046px) {
  .project {
    flex-direction: row;
    text-align: left;
  }

  #proj1, #proj3 {
    margin-left: 50px;
  }

  #proj2, #proj4 {
    margin-right: 50px;
  }

  .project-img {
    margin: 0 100px;
  }

  .pbtn {
    align-self: flex-start;
  }
}

@media screen and (min-width: 3900px) {
  #proj1, #proj3 {
    align-items: center;
    animation: right-to-left 0.8s ease-in-out forwards;
  }

   #proj2, #proj4 {
    animation: right-to-left 0.8s ease-in-out forwards;
  }

  .projects {
    align-items: center;
  }
}

/*********************** Hero section *************************/

.hero-container {
  display: flex;
  background: rgb(199, 164, 118);
}

.hero {
  margin: 5px;
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: 40% 60%;
}

.hero-about {
  font-size: 11px;
  flex-basis: 50%;
  font-family: 'Courgette', cursive;
  padding: 10px;
  letter-spacing: 1px;
  color: rgb(22, 21, 21);
  line-height: 1.3;
}

@media screen and (min-width: 596px) {
  .hero {
    width: 300px;
    height: 300px;
  }

  .hero-about {
    font-size: 14px;
  }
}

@media screen and (min-width: 838px) {
  .hero {
    width: 500px;
    height: 500px;
  }

  .hero-about {
    font-size: 22px;
  }
}

@media screen and (min-width: 1110px) {
  .hero {
    width: 900px;
    height: 500px;
    object-position: 60% 40%;
  }
}

/*********************** Search section *************************/

.search-container {
  display: flex;
  font-family: 'Noto Serif', serif;
}

.search-form {
  margin: 10px auto;
}

.form-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.form-container > label {
  font-size: 16px;
  color: #414040;
  letter-spacing: 1px;
  font-weight: bold;
}

#name {
  margin-left: 10px;
  padding-left: 3px;
  font-family: 'Noto Serif', serif;
}

.cat-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0 1rem;
  max-width: 70%;
  margin: 0 auto;
}

.cat-btn {
  background-color: transparent;
  letter-spacing: 1px;
  font-size: 16px;
  border: none;
  font-family: 'Volkhov', serif;
  margin: 0.2rem;
  cursor: pointer;
  color: hsl(39, 86%, 53%);
  padding: 2px;
  transition: all 0.3s linear;
}

.cat-btn.active {
  text-decoration: underline;
}

.cat-btn:hover, .cat-btn.active {
  background-color: hsl(39, 86%, 53%);
  color: white;
}

@media screen and (min-width: 462px) {
  .form-container > label {
    font-size: 25px;
  }

  #name {
    font-size: 18px;
  }
}

@media screen and (min-width: 670px) {
  .cat-btn {
    font-size: 24px;
  }
}

/*********************** Dish section *************************/

.dish-main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.dish-li {
  list-style: none;
}

.dish-container {
  margin: 10px 10px;
  transition: box-shadow 0.3s ease-in-out;
  max-width: 300px;
}

.dish-container:hover {
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.7);
}

.item {
  display: flex;
}

.name,
.category {
  max-width: 350px;
  font-size: 23px;
  font-weight: bold;
  background: rgba(210, 105, 30, 0.7);
  text-align: center;
}

.name {
  letter-spacing: 2px;
  font-size: 23px;
  font-family: 'Noto Serif', serif;
  width: 270px;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-img {
  height: 270px;
  width: 270px;
  margin: 0;
  padding: 0;
}

.instructions {
  overflow: scroll;
  width: 200px;
  height: 200px;
}

@media screen and (min-width: 400px) {
  .instructions {
    width: 300px;
  }
}

@media screen and (min-width: 500px) {
  .instructions {
    width: 400px;
  }
}

/*********************** DishInfo section *************************/

.back-btn {
  width: 120px;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  padding: auto;
  font-size: 23px;
  background: hsl(192, 93%, 48%);
  color: #000;
  text-decoration: none;
  margin: 10px;
  align-self: center;
  flex-shrink: 0;
}

.dish {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: chocolate;
  justify-content: space-evenly;
  padding: 15px;
  align-items: center;
  height: 100vh;
  overflow: auto;
}

.info-container {
  display: flex;
  flex-direction: column;
}

.ins {
  flex-basis: 100%;
}

.dish-img,
.dish-ins,
.dish-ing,
.dish-measure {
  letter-spacing: 1px;
}

.dish-img, .info-container {
  margin-bottom: 20px;
}

.dish-img {
  width: 250px;
  height: 250px;
}

.dish-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
}

.dish-ing,
.dish-measure {
  font-size: 25px;
  font-weight: bold;
}

.ing-measure-container {
  display: flex;
  justify-content: space-evenly;
}

.ing-list, .ing-measure-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.ing-list {
  margin-right: 20px;
}

.ing-measure-list {
  margin-left: 40px
}

.ing, .measure, .dish-ins-line {
  line-height: 200%;
  letter-spacing: 1px;
  font-family: 'Arvo', serif;
  font-size: 14px;
}

.dish-ins {
  font-size: 30px;
  display: block;
  font-weight: bold;
}

.dish-ins-line {
  font-size: 16px;
}

.dish-name, .dish-cat {
  letter-spacing: 1px;
  font-family: 'BIZ UDMincho', serif;
  color: #333333;
  font-size: 42px;
}

.d-name, .d-cat {
  display: inline-block;
  font-weight: bold;
  padding-left: 20px;
  font-family: 'Montserrat', sans-serif;
  
  font-size: 25px;
  letter-spacing: 5px;
  color: #000;
}

.ing-sec {
  margin-right: 10px;
}

.measure-sec {
  margin-left: 10px;
}

@media screen and (min-width: 768px) {
  .dish-img {
    width: 300px;
    height: 300px;
  }

  .ing, .measure {
    font-size: 20px;
  }

  .dish-ing,
  .dish-measure {
    font-size: 30px;
  }

  .dish-ins {
    font-size: 45px;
  }

  .dish-ins-line {
    font-size: 18px;
  }
}

@media screen and (min-width: 1000px) {

  .dish-ins-line {
    font-size: 20px;
  }

  .dish-img {
    width: 450px;
    height: 450px;
  }

  .dish-cat,
  .dish-name {
    align-self: center;
  }
}

@media screen and (min-width: 1200px) {
  .dish-img {
    width: 550px;
    height: 550px;
  }
}

/*************************** Calculator section ****************************/


.calculator-grid {
  display: grid;
  grid-template-columns: repeat(4, 5.5rem);
  grid-template-rows: minmax(7rem, auto) 4.5rem;
  background-color: hsl(256, 73%, 75%);
  justify-content: center;
  padding: 10px;
  height: 100vh;
  opacity: 0.95;
}

.calculator-grid > button {
  background-color: hsl(0, 0%, 83%);
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  outline: none;
  border: 1px solid white;
}

.screen {
  grid-column: span 4;
  background-color: hsl(0, 0%, 30%);
  padding: 0.25rem;
  font-size: 25px;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-direction: column;
  word-wrap: break-word;
  word-break: break-all;
  opacity: 0.9;
}

.AC, .result {
  grid-column: span 2;
}

.prev-op {
  font-size: 1rem;
}

.curr-op {
  font-size: 2rem;
}

/*************************** Calendar section ****************************/

.cal-container {
  height: 100vh;
  background-color: hsl(180, 73%, 53%);
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: column;
}

.month-btn-container {
  display: flex;
  align-items: flex-end;
  margin: 2% 0 5px 0;
  align-self: center;
  width: 300px;
}

.prev-month-btn {
  margin-right: auto;
}
.next-month-btn {
  margin-left: auto;
}

.calendar {
  border: 3px solid hsl(216, 67%, 44%);
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.num-days {
  display: grid;
  flex: 1 1 0px;
  grid-template-columns: repeat(7, 1fr);
}

.week-days {
  display: flex;
  flex: 1 1 0px;
  flex-basis: 100%;
}

.week-day, .num-day {
  padding: 5px 5px;
  border: 2px solid hsl(216, 67%, 44%);
  font-size: 8px;
  background-color: hsl(0, 0%, 93%);
  align-self: flex-start;
}

.week-day {
  text-align: center;
  flex: 1 1 0px;
  font-family: 'Rubik', sans-serif;
}

.num-day {
  height: 95px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}

.events-btn {
  cursor: pointer;
}

.add-event-btn, .prev-month-btn, .next-month-btn {
  border: 2px solid lightgrey;
	background-color: #fff;
	font-size: 16px;
	height: 1.5em;
	width: 1.5em;
	border-radius: 999px;
	position: relative;
  cursor: pointer;
}

.add-event-btn::before,
.add-event-btn::after {
  content: "";
		display: block;
		background-color: rgb(0, 162, 255);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
}

.prev-month-btn::before, .next-month-btn::before,
.prev-month-btn::after, .next-month-btn::after {
  content: "";
		display: block;
		background-color: rgb(0, 162, 255);
		position: absolute;
		top: 50%;
		left: 50%;
}

.prev-month-btn::before {
  transform: rotate(-45deg) translate(-2px, -4px);
}

.prev-month-btn::after {
  transform: rotate(-45deg) translate(-3px, -10px);
}

.next-month-btn::before {
  transform: rotate(45deg) translate(0px, -3px);
}

.next-month-btn::after {
  transform: rotate(45deg) translate(-6px, -2px);
}

.add-event-btn::before, .prev-month-btn::before, .next-month-btn::before {
  height: 0.8em;
  width: 0.2em;
}

.add-event-btn::after, .prev-month-btn::after, .next-month-btn::after {
  height: 0.2em;
  width: 0.8em;
}

/********************************* Modals section **********************************/

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  min-width: 300px;
  height: 200px;
  z-index: 500;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.day-event {
  height: 90%;
  width: 90%;
  word-wrap: break-word;
  margin: 5px 15px;
}

.modal-btn {
  z-index: 500;
  margin: 3px;
  width: 25px;
  height: 25px;
  font-size: 18px;
  background-color: white;
  border: none;
  color: red;
  margin-left: auto;
  cursor: pointer;
}

.modal-btn:active {
  background-color: #bbbbbb;
  border-radius: 50%;
}

.event-list-item {
  display: flex;
  font-size: 20px;
  color: hsl(216, 100%, 50%);
  justify-content: space-between;
  margin: 5px 0;
  font-family: 'Playfair Display', serif;
  word-break: break-all;
  font-family: 'Mukta', sans-serif;
}

.date-title {
  font-size: 26px;
  font-weight: bold;
  color: hsl(172, 89%, 25%);
  font-family: 'Roboto Slab', serif;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 500;
}

.form-body {
  display: flex;
  flex-direction: column;
  font-family: 'Mukta', sans-serif;
}

.form-title {
  font-size: 32px;
  font-weight: bold;
  margin: 15px 10px;
}

.form-input {
  margin: 2rem 10px;
  letter-spacing: 1px;
}

.form-input-box {
  border: 1px solid black;
}

.form-label {
  font-size: 24px;
  color: #535353;
}

.form-modal-body {
  display: flex;
  flex-direction: column;
}

.form-add-btn {
  width: 50px;
  height: 30px;
  background-color: hsl(197, 100%, 61%);
  border: none;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.7);
  font-size: 18px;
  font-family: 'Mukta', sans-serif;
  display: inline-block;
  align-self: flex-end;
  margin-top: 38px;
  margin-right: 2px;
}

.back-edit-btn {
  margin-left: auto;
  display: flex;
  font-weight: bold;
  letter-spacing: 1px;
}

@media screen and (min-width: 430px) {
  .week-day, .num-day {
    font-size: 10px;
  }
}

@media screen and (min-width: 500px) {
  .week-day, .num-day {
    font-size: 12px;
  }
}

@media screen and (min-width: 560px) {
  .week-day, .num-day {
    font-size: 14px;
  }

  .modal-container {
    width: 400px;
    height: 280px;
  }
}

@media screen and (min-width: 712px) {
  .week-day, .num-day {
    font-size: 18px;
  }

  .modal-container {
    width: 500px;
  }
}

@media screen and (min-width: 900px) {
  .num-day {
    flex-direction: row;
    align-items: flex-end;
  }
}

@media screen and (min-width: 1050px) {

  .modal-container {
    width: 600px;
    height: 350px;
  }

  .form-input {
    margin: 4rem 10px;
  }

  .week-day, .num-day {
    font-size: 22px;
  }
}

/*********************************** Tours *****************************************/

.tours {
  background-color: #f1f5f8;
  font-family: -apple-system,BlinkMacSystemFont,
  "Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,
  "Open Sans","Helvetica Neue",sans-serif;
}

 .tour {
   display: flex;
   flex-direction: column;
   width: 600px;
   background-color: white;
   box-shadow: 0 5px 15px rgba(0,0,0,0.2);
   margin: 1rem 0;
   align-items: center;
 }

 .tour-flip {
  position: absolute;
  width: 600px;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
  margin: 1rem 0;
  display: none;
}

 .activeTour {
   display: flex;
   transform: translateX(0);
 }

 .tours-container {
   display: flex;
   justify-content: center;
   background-color: #f1f5f8;
   height: 100vh;
 }

 .prev-tour {
    animation: slide-out-left 350ms ease-out;
 }

 .next-tour {
    animation: slide-in-left 350ms ease-in;
 }

@keyframes slide-in-left {
  0% {
    transform: translateX(500px);
  }
}

@keyframes slide-out-left {
 100% {
   transform: translateX(-500px);
 }
}

 .tour-img {
   width: 400px;
   height: 400px;
 }

 .tour-title-price {
   display: flex;
   justify-content: space-between;
   width: 100%;
   padding: 20px;
   font-weight: 700;
   letter-spacing: 2px;
 }

 .tour-price {
   background-color: #e9eff5;
   color: #40afff;
   padding: 0 15px;
   font-size: 17px;
   border-radius: 3px;
 }

 .tour-desc {
   padding: 20px;
   color: hsl(216, 24%, 48%);
   line-height: 1.5;
 }

 .not-int-btn {
   border: 1px solid hsl(0, 79%, 42%);
   font-size: 16px;
   background-color: white;
   color: hsl(0, 79%, 42%);
   padding: 4px 2.5rem;
   margin-bottom: 1.5rem;
   border-radius: 5px;
   letter-spacing: 1.5px;
   cursor: pointer;
 }

 .tour-read-more-btn {
    color: #40afff;
    font-size: 1rem;
    background-color: white;
    border: none;
    cursor: pointer;
 }

 .no-tours {
   display: flex;
   align-items: center;
   flex-direction: column;
   margin-top: 2rem;
 }

 /********************************************** CA-Clone ****************************************************/

 .ca-clone-container {
  margin: 20px;
  /* width: 100%; */
 }

/*************** CA-Nav *****************/

.ca-nav {
  width: 100%;
  /* height: fit-content; */
  display: flex;
  padding: 0.9rem;
  align-self: flex-start;
}

.ca-nav-items {
  /* display: flex; */
  list-style: none;
  justify-self: flex-start;
}

.ca-logo {
  width: 60px;
  height: 60px;
  margin: 0 4.5rem 0 6rem;
}

.ca-nav-item {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.8rem;
  color: white;
  padding: 15px 30px;
  letter-spacing: -1px;
  transition: color 0.2s linear;
  display: inline-block;
}

.ca-nav-item:hover {
  color: #939393;
}

.ca-nav-item > a {
  text-decoration: none;
  color: inherit;
}

.ca-left-arrow, .ca-right-arrow {
  cursor: pointer;
  align-self: center;
  transition: all 0.4s linear;
}

.ca-left-arrow:hover, .ca-right-arrow:hover {
  background-color: #535353;
}

.header-hamburger {
  opacity: 0;
  position: absolute;
}

@media screen and (max-width: 996px) {
  .ca-nav-items {
    opacity: 0;
  }

  .ca-logo {
    margin: 0;
  }

  .ca-logo-active {
    width: 110px;
    height: 90px;
  }

  .header-hamburger {
    opacity: 1;
    top: 5%;
    right: 2.5%;
    z-index: 101;
  }

  .ca-nav-active {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    z-index: 100;
    padding-top: 3rem;
    text-align: center;
  }

  .ca-nav-items-active {
    opacity: 1;
    list-style: none;
    display: flex;
    flex-direction: column;
  }

  .ca-nav-item {
    color: black;
  }
}

/***************** Header Lorems ******************/

.header-main {
  background-size: cover;
  /* background-repeat: no-repeat; */
  min-height: 95vh;
  display: flex;
  background-color: #000;
  flex-direction: column;
  transition: all 0.5s linear;
  /* animation: fade-in-out 1s 5s forwards; */
}

@keyframes fade-in-out {
  0% {
    opacity: 0.8;
  }

  33% {
    opacity: 0.5;
  }

  66% {
    opacity: 0.8;
    background-color: #000;
    background-image: none;
  }

  100% {
    opacity: 1;
    background-color: #000;
    background-image: none;
  }
}

.header-main > span {
  align-self: center;
  color: white;
  margin: 0 10px;
}

.slide-container {
  text-align: right;
  width: 75%;
  white-space: pre-line;
  margin: 175px auto 0 auto;
}

.header-content-container {
  width: 100%;
  color: white;
  display: flex;
  flex-grow: 1;
  padding: 0 10px;
}

.header-lorem {
  text-align: right;
  color: white;
  opacity: 0;
  position: absolute;
}

.header-lorem-active {
  position: relative;
  opacity: 1;
}

.header-lorem > h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 3.5rem;
}

.header-lorem > h3 {
  font-family: 'Vollkorn', serif;
  font-weight: 100;
  font-style: italic;
}

@media screen and (max-width: 786px) {
  .header-lorem > h1 {
    font-size: 2.5rem;
  }

  .slide-container {
    margin: 0 auto;
  }
}

@media screen and (max-width: 410px) {
  .header-lorem > h1 {
    font-size: 1.5rem;
  }
}

/***************** MainContent *******************/

#ca-main-img1 {
  display: none;
}

.ca-container-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.ca-container-row:nth-child(1) {
  margin-top: 0.75rem;
}

.ca-info, .ca-bottom-info {
  max-width: 100%;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0.75rem 0;
}

.ca-main-icon {
  margin-top: 1rem;
}

.ca-info > h1, .ca-bottom-info > h1 {
  white-space: pre-line;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  letter-spacing: -3px;
  margin-top: 1rem;
}

.ca-info > h3 {
  font-family: 'Vollkorn', serif;
  font-style: italic;
  font-weight: 100;
  font-size: 1rem;
  opacity: 0;
}

.ca-info > h1 {
  opacity: 0;
}

.h1-active {
  animation: fade-up 0.8s ease-in-out forwards;
}

.h3-active {
  animation: fade-right 0.6s 0.7s ease-in-out forwards;
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-right {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.ca-info > p, .ca-bottom-info > p {
  margin: 1.2rem 0.7rem;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.8rem;
  color: rgb(65, 65, 65);
  line-height: 1.7;
}

.ca-info > button {
  width: 10rem;
  height: 3.3rem;
  margin-bottom: 1rem;
  border: 2px solid #666666;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: -1px;
  font-size: 0.75rem;
  cursor: pointer;
  transition: all 0.25s linear;
}

.ca-info > button:hover {
  color: white;
  background-color: #666;
}

.ca-main-img {
  max-width: 100%;
  margin: 0.75rem 0;
}


@media screen and (min-width: 766px) {
  .ca-main-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .ca-container-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0.75rem 0;
    flex-basis: 100%;
    height: 600px;
    /* width: 50%; */
  }

  .ca-container-row:first-child {
    margin-top: 1.5rem;
  }

  #ca-main-img1 {
    display: initial;
  }
  
  .ca-main-img, .ca-info {
    width: 48%;
    flex-grow: 1;
    margin: 0;
  }

  .ca-info, .ca-bottom-info {
    justify-content: center;
  }

  .ca-info {
    padding: 5rem 4rem;
  }

  .ca-info > h1 {
    font-size: 2.5rem;
  }

  .ca-info > p {
    font-size: 0.9rem;
  }

  .ca-info:nth-child(even), .ca-main-img:nth-child(even) {
    margin-left: 0.75rem;
  }

  .ca-info:nth-child(odd), .ca-main-img:nth-child(odd) {
    margin-right: 0.75rem;
  }

  /********** ca main bottom **********/

  .ca-bottom-main {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
  }

  .ca-bottom-info {
    width: 33.3%;
    opacity: 0;
  }

  .ca-bottom-info-active1 {
    animation: up-fade-in 1s ease-out forwards;
  }

  .ca-bottom-info-active2 {
    animation: up-fade-in 1s 0.2s ease-out forwards;
  }

  .ca-bottom-info-active3 {
    animation: up-fade-in 1s 0.4s ease-out forwards;
  }

  @keyframes up-fade-in {
    0% {
      transform: translateY(200px);
      opacity: 0;
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .ca-bottom-info:nth-child(2) {
    margin: 0.75rem 1.5rem;
  }
  
}

/*********** ca footer *************/

.ca-footer {
  width: 100%;
  background-color: #212121;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0 2rem 0;
}

.ca-footer > img, .footer-info-container > h5 {
  margin: 1rem 0;
}

.ca-footer > img {
  width: 70px;
  height: 70px;
}

.footer-info-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 1rem 0;
}

.footer-info-container > h5 {
  color: #7d7d7d;
  font-family: 'Montserrat', sans-serif;
}

.footer-info-container > p, .footer-info-container > a {
  color: white;
  line-height: 2.5;
  white-space: pre-line;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.8rem;
}

.footer-info-container > a {
  text-decoration: none;
  color: white;
}

@media screen and (min-width: 767px) {
  .ca-footer {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 2rem 0;
  }
  
  .footer-info-container {
    text-align: left;
    height: max-content;
    margin: 0;
  }

  .footer-info-container > p, .ca-footer > img, .footer-info-container > a {
    margin: 0 0 1.5rem 0;
  }
}